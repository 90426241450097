<template>
  <div class="col-lg-3">
    <side-nav
      :items="navigationItems"
    />
  </div>
  <div 
    class="col-lg-9 mt-lg-0 mt-4"
  >
    <user-profile
      v-model:profilePicture="fields.profilePicture"
      v-model:profilePictureData="fields.profilePictureData"
      :company-name="fields.companyName"
      :full-name="fullName"
      :profession="fields.profession"
    />

    <form @submit.prevent="submit">
      <base-fieldset
        id="basic-info"
        title="Basis informatie"
      >
        <basic-info
          v-model:description="fields.description"
          v-model:emailAddress="fields.emailAddress"
          v-model:firstName="fields.firstName"
          v-model:insertion="fields.insertion"
          v-model:lastName="fields.lastName"
          v-model:phoneNumber="fields.phoneNumber"
          :show-update="!isNewUser"
        />
      </base-fieldset>

      <base-fieldset
        id="user-groups"
        title="Gebruikersgroepen"
      >
        <user-groups
          ref="userGroupsRef"
          :show-update="!isNewUser"
        />
      </base-fieldset>
      
      <base-fieldset
        v-if="!companyModuleActive"
        id="company-info"
        title="Bedrijfsinformatie"
      >
        <company-info
          v-model:address="fields.address"
          v-model:city="fields.city"
          v-model:companyName="fields.companyName"
          v-model:profession="fields.profession"
          v-model:website="fields.website"
          v-model:zipcode="fields.zipcode"
          :show-update="!isNewUser"
        />
      </base-fieldset>

      <base-fieldset
        v-if="companyModuleActive"
        id="company-info"
        title="Werkzaam bij"
      >
        <user-companies
          ref="userCompaniesRef"
          :companies="fields.companies"
          :show-update="!isNewUser"
        />
      </base-fieldset>
      
      <base-fieldset
        id="user-socials"
        title="Socials"
      >
        <user-socials
          v-model:facebook="fields.facebook"
          v-model:linkedIn="fields.linkedIn"
          v-model:twitter="fields.twitter"
          v-model:instagram="fields.instagram"
          :show-update="!isNewUser"
        />
      </base-fieldset>

      <base-fieldset
        id="user-permissions"
        intro="Gebruikers binnen de app kunnen verschillende rechten hebben. Vul hieronder 
          de juiste rechten in"
        title="Rechten"
      >
        <user-permissions
          v-model:boardMemberFunction="fields.boardMemberFunction"
          v-model:isBoardMember="fields.isBoardMember"
          v-model:roleId="fields.roleId"
          :show-update="!isNewUser"
        />
      </base-fieldset>

      <base-fieldset
        v-if="projectsModuleActive"
        id="user-projects"
        title="Verbonden besloten items"
      >
        <user-projects 
          ref="userProjectsRef"
          :projects="fields.projects"
          :show-update="!isNewUser"
        />
      </base-fieldset>
      
      <div
        v-if="!isNewUser"
        id="delete"
        class="card mt-4"
      >
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="w-50">
              <h5>Verwijder deze gebruiker</h5>
              <p class="text-sm mb-0">
                Wanneer een account verwijderd wordt, kan dit niet meer ongedaan gemaakt worden.
              </p>
            </div>
            <div class="w-50 text-end">
              <material-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                @click.prevent="remove"
              >
                Delete Account
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <material-button
        v-if="isNewUser"
        color="primary"
        variant="gradient"
        class="float-end mt-4 mb-0 mx-4"
      >
        Opslaan
      </material-button>
    </form>
  </div>
</template>

<script>
import { moduleIsActive } from '@/helpers/module';
import { mapActions, mapState } from 'vuex';
import { add, edit, getEdit, remove } from '@/api/providers/users';
import { parseFullName } from '@/helpers/users';
import BaseFieldset from "@/components/Forms/BaseFieldset.vue";
import BasicInfo from "@/components/AppUsers/Edit/BasicInfo.vue";
import CompanyInfo from "@/components/AppUsers/Edit/CompanyInfo.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import SideNav from "@/components/UI/SideNav.vue";
import UserCompanies from '@/components/AppUsers/Edit/UserCompanies.vue';
import UserGroups from '@/components/AppUsers/Edit/UserGroups.vue';
import UserGroupStore from '@/store/pages/AppUserEdit/user-groups';
import UserPermissions from "@/components/AppUsers/Edit/UserPermissions.vue";
import UserProfile from "@/components/AppUsers/Edit/UserProfile.vue";
import UserSocials from "@/components/AppUsers/Edit/UserSocials.vue";
import UserProjects from "@/components/AppUsers/Edit/UserProjects.vue";
import { ResponseError } from '../../helpers/error';

export default {
  name: 'AppUsersEdit',

  components: {
    BaseFieldset,
    BasicInfo,
    CompanyInfo,
    MaterialButton,
    SideNav,
    UserCompanies,
    UserGroups,
    UserPermissions,
    UserProfile,
    UserSocials,
    UserProjects
  },

  data: () => ({
    fields: {
      address: '',
      boardMemberFunction: '',
      city: '',
      companyName: '',
      companies: [],
      clubs: [],
      description: '',
      emailAddress: '',
      facebook: '',
      firstName: '',
      insertion: '',
      isBoardMember: false,
      lastName: '',
      linkedIn: '',
      instagram: '',
      phoneNumber: '',
      profession: '',
      profilePicture: '',
      profilePictureData: '',
      roleId: 0,
      twitter: '',
      website: '',
      zipcode: '',
      projects: []
    },
    loading: false,
    companyModuleActive: false,
    projectsModuleActive: false
  }),

  computed: {
    ...mapState('account', [
      'auth',
    ]),

    fullName() {
      const { firstName, insertion, lastName } = this.fields;
      return parseFullName(firstName, insertion, lastName);
    },

    isNewUser() {
      return this.$route.params?.id === undefined;
    },

    userId() {
      return this.$route.params?.id ?? 0;
    },

    navigationItems() {
      return [
        {
          href: '#profile',
          icon: 'person',
          name: 'Profile',
        },
        {
          href: '#basic-info',
          icon: 'contacts',
          name: 'Basic Informatie',
        },
        {
          href: '#user-groups',
          icon: 'diversity_3',
          name: 'Gebruikersgroepen'
        },
        {
          href: '#company-info',
          icon: 'business',
          name: 'Bedrijfsinformatie',
        },
        {
          href: '#user-socials',
          icon: 'public',
          name: 'Social',
        },
        {
          href: '#user-permissions',
          icon: 'lock',
          name: 'Rechten',
        },
      ]
      .concat(
        this.projectsModuleActive
          ? {
              href: '#user-projects',
              icon: 'hive',
              name: 'Besloten items'
            }
          : []
      )
      .concat(
        !this.isNewUser
          ? {
              href: '#delete',
              icon: 'delete',
              name: 'Delete Account',
            }
          : []
      );
    },

    submitData() {
      return {
        ...this.fields,
        companies: undefined,
        ...(
          this.isNewUser
            ? {}
            : {
              id: this.$route.params.id
            }
        ),
        organizationId: this.auth.organizationId,
      };
    },
  },

  async mounted() {
    this.companyModuleActive = await moduleIsActive('CompanyPage');
    this.projectsModuleActive = await moduleIsActive('Projects');

    this.loadUserData();
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    async addUser() {
      try {
        let createdUser = await add(this.submitData);
        if (this.$refs.userCompaniesRef !== undefined) {
          await this.$refs.userCompaniesRef.submit(createdUser.id);
        }

        if (this.$refs.userGroupsRef !== undefined) {
          await this.$refs.userGroupsRef.submit(createdUser.id);
        }

        this.handleSaveSuccess();
      }
      catch(error) {
        (new ResponseError(error)).default();
      }
      this.loading = false;
    },

    async editUser() {
      try {
        let updatedUser = await edit(this.submitData);
        if (this.$refs.userCompaniesRef !== undefined) {
          await this.$refs.userCompaniesRef.submit(updatedUser.id);
        }

        if (this.$refs.userProjectsRef !== undefined) {
          await this.$refs.userProjectsRef.submit(updatedUser.id);
        }

        if (this.$refs.userGroupsRef !== undefined) {
          await this.$refs.userGroupsRef.submit(updatedUser.id);
        }

        this.handleSaveSuccess();
      } 
      catch(error) {
        (new ResponseError(error)).default();
      }
      this.loading = false;
    },

    handleGetSuccess(data) {
      Object.entries(data).forEach(([key, value]) => {
        this.fields[key] = value;
      });

      UserGroupStore.dispatch('initializeGroups', data.groups ?? []);
    },

    handleSaveSuccess() {
      this.addNotification({
        description: `${this.fullName} is opgeslagen.`,
        icon: {
          component: 'person',
        },
      });

      this.navigateToAppUsersOverview();
    },

    loadUserData() {
      if (this.isNewUser) {
        UserGroupStore.dispatch('clear');
        return;
      }

      getEdit(this.$route.params.id)
        .then(this.handleGetSuccess)
        .catch((error) => console.error(error));
    },

    navigateToAppUsersOverview() {
      this.$router.push({
        name: 'app-users',
      });
    },

    remove() {
      remove(this.$route.params.id, this.fullName)
        .then(this.navigateToAppUsersOverview)
        .catch((error) => error.default());
    },

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (this.isNewUser) {
        this.addUser();
      } else {
        this.editUser();
      }
    },
  },
}
</script>
